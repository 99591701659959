import { useEffect } from 'react';
import useAuth from '../../../hooks/use-auth';
import { GlobalStyle, LoadingWheel, ThemeProvider } from '@vinomofo/components';
import AuthProvider from '../../../contexts/auth/auth-provider';
import styled from 'styled-components';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const autoLogin = (props: { refCode: string }) => {
  const { loginWithCredentials } = useAuth();

  const login = async (ref: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_IDENTITY_API_URL}/get-login-details?ref=${encodeURIComponent(ref)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.log(errorData);
      window.location.href =
        'https://secure.vinomofo.com/login?client=j6aKjgXtSYwmO2BCwAYteHl3aam6Q4Lt&tenantDomain=vinomofo.au.auth0.com&audience=com.vinomofo%3A%2Fapi&#/auth/login/using-password';
    }

    const result = await response.json();
    const doc = result?.data?.doc;

    if (!doc) {
      throw Error('Login details cannot be found!');
    }
    try {
      await loginWithCredentials({ email: doc.email, password: doc.password });
    } catch (e) {
      window.location.href =
        'https://secure.vinomofo.com/login?client=j6aKjgXtSYwmO2BCwAYteHl3aam6Q4Lt&tenantDomain=vinomofo.au.auth0.com&audience=com.vinomofo%3A%2Fapi&#/auth/login/using-password';
    }
  };

  useEffect(() => {
    if (props.refCode) {
      login(props.refCode);
    } else {
      window.location.href =
        'https://secure.vinomofo.com/login?client=j6aKjgXtSYwmO2BCwAYteHl3aam6Q4Lt&tenantDomain=vinomofo.au.auth0.com&audience=com.vinomofo%3A%2Fapi&#/auth/login/using-password';
    }
  }, []);

  return (
    <ThemeProvider>
      <GlobalStyle />
      <AuthProvider>
        <CenteredContainer>
          <LoadingWheel />
        </CenteredContainer>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default autoLogin;
